import React, { ReactNode } from 'react'
import CssBaseline from '@material-ui/core/CssBaseline'
import { responsiveFontSizes, ThemeProvider } from '@material-ui/core/styles'
import tdcTheme from '@tdcerhverv/mui-theme'

const theme = responsiveFontSizes(tdcTheme)

const ThemeLayout = ({ children }: { children: ReactNode }) => {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {children}
    </ThemeProvider>
  )
}

export default ThemeLayout
