module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-material-ui/gatsby-browser.js'),
      options: {"plugins":[],"disableAutoprefixing":true,"disableMinification":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"tdc-dk-shop","short_name":"tdcdkshop","start_url":"/","background_color":"#FFFFFF","theme_color":"#0000BF","display":"minimal-ui","icon":"src/images/tdc-icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"d0da2617a2bfed0d96ade14555e9c053"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
