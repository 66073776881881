import React from 'react'
import PageDataProvider from './src/context/PageProvider'
import ThemeLayout from './src/components/Layout/ThemeLayout'

// Wraps every page in a component
export const wrapPageElement = ({ element, props }) => {
  return (
    <PageDataProvider {...props}>
      <ThemeLayout>{element}</ThemeLayout>
    </PageDataProvider>
  )
}

export const onRouteUpdate = () => {
  if (typeof window._satellite === 'undefined') return

  const routeChangeEventName = 'pageLoad' //may need to define event in Adobe Analytics to manually track page loads

  // wrap inside a timeout to make sure react-helmet is done with it's changes (https://github.com/gatsbyjs/gatsby/issues/9139)
  // reactHelmet is using requestAnimationFrame: https://github.com/nfl/react-helmet/blob/5.2.0/src/HelmetUtils.js#L296-L299
  setTimeout(() => {
    // Dispatch route change event to target in a rule.
    window._satellite.track(routeChangeEventName)
  }, 32)
}
