// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-betaling-afvist-tsx": () => import("./../../../src/pages/betaling-afvist.tsx" /* webpackChunkName: "component---src-pages-betaling-afvist-tsx" */),
  "component---src-pages-betaling-godkendt-tsx": () => import("./../../../src/pages/betaling-godkendt.tsx" /* webpackChunkName: "component---src-pages-betaling-godkendt-tsx" */),
  "component---src-pages-checkud-tsx": () => import("./../../../src/pages/checkud.tsx" /* webpackChunkName: "component---src-pages-checkud-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-kurv-tsx": () => import("./../../../src/pages/kurv.tsx" /* webpackChunkName: "component---src-pages-kurv-tsx" */),
  "component---src-pages-produkt-product-id-tsx": () => import("./../../../src/pages/produkt/[productId].tsx" /* webpackChunkName: "component---src-pages-produkt-product-id-tsx" */),
  "component---src-pages-search-results-index-tsx": () => import("./../../../src/pages/search-results/index.tsx" /* webpackChunkName: "component---src-pages-search-results-index-tsx" */)
}

